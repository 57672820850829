// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-imprint-de-js": () => import("./../../../src/pages/imprint.de.js" /* webpackChunkName: "component---src-pages-imprint-de-js" */),
  "component---src-pages-imprint-es-js": () => import("./../../../src/pages/imprint.es.js" /* webpackChunkName: "component---src-pages-imprint-es-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-info-de-js": () => import("./../../../src/pages/info.de.js" /* webpackChunkName: "component---src-pages-info-de-js" */),
  "component---src-pages-info-es-js": () => import("./../../../src/pages/info.es.js" /* webpackChunkName: "component---src-pages-info-es-js" */),
  "component---src-pages-privacy-de-js": () => import("./../../../src/pages/privacy.de.js" /* webpackChunkName: "component---src-pages-privacy-de-js" */),
  "component---src-pages-privacy-es-js": () => import("./../../../src/pages/privacy.es.js" /* webpackChunkName: "component---src-pages-privacy-es-js" */)
}

